.raise_issue_header{
    border-bottom: 1px solid rgba(128, 128, 128, 0.386);
    padding: 5px;
    height: fit-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.raise_issue_body{
    margin: 10px 5%;
}

.ret_shp_nm{
    width: 250px;
}
.ret_order_id_inp{
    width: 120px;
}

.orderSearchBtn{
    margin-top: 10px ;
    margin-left: 15px;
}
.issueType_chip{
    margin: 5px !important;
}
.issue_attach_image{
    max-width: 300px;
    width: fit-content;
    text-overflow: ellipsis ;
    overflow: hidden ;
    white-space: nowrap;
    margin: 5px 15px 5px 5px;
    font-size: 14px;
}

.issue_details_modal{
    background-color: #F5F5F5 !important;
}

.resolved_state{
    margin-top: 20px;
    max-width: 370px;
    font-size: 12px;
    border: 1px solid #249E6C;
    padding:8px;
    border-radius: 8px;
    background-color: #E3F9F0;
    word-wrap: break-word;

}
.resolved_state > b{
    color: #249E6C;
}

.open_state{
    margin-top: 20px;
    max-width: 370px;
    font-size: 12px;
    border: 1px solid #AF8702;
    padding:8px;
    border-radius: 8px;
    background-color: #FFF4D3;
    word-wrap: break-word;
}
.open_state > b{
    color: #AF8702;
}
.rejected_state{
    margin-top: 20px;
    max-width: 370px;
    font-size: 12px;
    border: 1px solid #891B0C;
    padding:8px;
    border-radius: 8px;
    background-color: #FCDDD9;
    word-wrap: break-word;

}
.rejected_state > b{
    color: #891B0C;
}
.progress_state{
    margin-top: 20px;
    max-width: 370px;
    font-size: 12px;
    border: 1px solid #FDCC2B;
    padding:8px;
    border-radius: 8px;
    background-color: #FFF4D3;
    word-wrap: break-word;

}
.progress_state > b{
    color: #FDCC2B;
}

.stepper_div{
    width: 50%;
    display: flex;
    justify-content: center !important; 
    flex-direction: row;
    align-items: start;
}

.send_msg_footer{
    width: 350px;
    margin: 0px auto;
}
.send_btn_issue_details{
    width: 60px;
    margin: 0px 10px !important;
}
.raise_issue_footer{
    padding: 15px;
}
.raise_issue_footer > button:first-child{
     margin-right: 25% !important;
}
.raise_issue_footer >button:last-child{
    min-width: 100px !important;
}

.success_case{
    background-color: #F5F5F5 !important;
    text-align: center;
    font-size: 12px;
}
.success_case_desc{
    line-height: 16px !important; 
    margin-top: 20px;
    margin-bottom: 10px;
}
.issue_desc{
    width: 50%;
}
.issue_info{
   /* width: 100%; */
   border-radius: 10px;
   padding: 10px;
}

.images_div{
    display: flex;
    padding: 10px;
    background-color: rgb(245, 243, 243);
    overflow: scroll;
    border-radius: 10px;
}
.images_div img{
    width: 100px;
    height: 70px;
    margin: 0px 15px;
    cursor: pointer;
}
.images_div::-webkit-scrollbar{
    display: none;
}

.chat_div{
    width: 50%;
   background-color: white;
   border-radius: 10px;
  max-height: 400px;
   min-height: 200px;
   overflow-y: scroll;
   color: grey !important;
   font-size: 12px;
   padding: 10px;
}
.chat_div::-webkit-scrollbar{
    display: none;
}
.chat_date{
    text-align: center;
    color: #B9B9B9;
    margin: 10px 0px;
    font-weight: bolder;
    position: sticky;
    top: -10px;
    background-color: white;
    padding: 5px;
    /* z-index: 1 ; */
}
.chat_msg_admin{

}
.chat_msg_ret{
   text-align: start;
   margin-left: auto;
   margin-right: 0px;
   margin-top: 10px;
   background-color: #E3F8F7;
   color: #2A2A2A;
   border: 1px solid #86E1DD;
   border-radius: 10px;
   padding: 10px;
   width: fit-content;
}

.chat_img{
    width: 180px ;
    cursor: pointer;
    word-wrap: break-word;
    text-decoration: none;
}
.chat_doc_link{
    max-width: 150px !important;
    cursor: pointer;
    word-wrap: break-word !important;
}
.chat_doc_link a{
text-decoration: none;
}

.issue_details_footer{
    position: sticky;
    bottom: 10px;
    margin: 15px;
}
.raise_issue_header{
    position: sticky;
    top: 0px;
    background-color: #F5F5F5;
}

.issue_desc_input{
    width: 60%;
}

.chat_msg_admin{
    text-align: start !important;
    margin-top: 10px;
    background-color: white;
    color: #2A2A2A;
    border: 1px solid rgba(128, 128, 128, 0.288);
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
}
.cancel_issue_details{
    position: relative;
    left: 40%;
}
.chat_timestamp{
    position: relative;
    font-size: 10px;
    color:#888787 ;
    margin: 0px;
    padding: 0px;
    top: 5px;
    text-align: right;
    z-index: 1 ;
}
.doc_view_btn{
    width:100px !important;
    padding:5px;
    margin:0px 10px;
    word-wrap: break-word !important;
    border: 1px solid rgba(87, 86, 86, 0.227);
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;

}
@media only screen and (max-width: 750px) {
    .issue_desc_input{
        width: 90%;
    }
    
    .issue_desc{
        width: 100%;
        margin-bottom: 20px;
    }
    .chat_div{
        width: 100%;
    }
    .send_msg_footer{
        width: 270px ;
    }
    .cancel_issue_details{
        left: 30%;
    }
    .ret_order_id_inp{
        margin-bottom: 20px !important;
    }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
    .cancel_issue_details{
        left: 35%;
    }
  }