.login-cntr {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saveo_logo {
  display: none;
}
.saveo1_logo{
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.error-message{
  color: red;
  font-size: small;
  text-Align: left;
  font-family: "Roboto", sans-serif;

}
.error-message1{
  color: red;
  font-size: large;
  text-Align: center;
  font-family: "Roboto", sans-serif;

}
.success-message{
  color: #43cdc8;
  font-size: large;
  text-Align: center;
  font-family: "Roboto", sans-serif;

}



.logo-container {
  height: 100vh;
  width: auto;
  display: grid;
  place-items: center;
  overflow: hidden;
  justify-content: center;

}

.login-section {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.login-panel {
  padding: 2rem;
  height: 500px;
  width: 400px;
  border-radius: 32px;
  background-color: white;
  margin: 1rem;
  text-align: center !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.login-header-cntr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.login-header {
  text-align: left;
}

.login-header p {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-family: "Roboto", sans-serif !important;
}

.login-header h3 {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
}

.login-pin {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  text-align: left;
  margin-top: 0;
}
#para {
  font-size: 0.9rem;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Roboto", sans-serif !important;
  text-align: left;
}
.login-header h5 {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  margin-bottom: 3rem;
  margin-top: 1rem;
  margin-right: 6rem;
}

.login-inpt-cntr {
  width: 100%;
  height: 100%;
}

.login-inpt-cntr label {
  font-size: 0.8rem;
  color: #2a2a2a;
  text-align: left;
  width: 100%;
  font-family: "Roboto", sans-serif !important;
}

.login-inpt-box {
  margin: 0.5rem 0 1.5rem 0;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  background-color: #f5f5f5;
  border-radius: 12px;
}

.login-inpt-box p {
  margin: 0;
  padding: 0.5rem;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif !important;
}

.login-inpt-box input {
  padding: 0 0.5rem;
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif !important;
}

.login-inpt-box .phn-inpt {
  border-left: 1px solid #a2a2a2;
  letter-spacing: 3px;
}

.login-inpt-box .inpt-pass {
  padding: 0.5rem;
}

.login-inpt-box input:-internal-autofill-selected {
  background-color: transparent !important;
}

.pass-link-wrapper {
  display: flex;
  align-items: center;
  justify-content:flex-end;
  width: 100%;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.pass-link-wrapper .check-box {
  margin: 0px;
  color: #a2a2a2;
}

.pass-link-wrapper .check-box input {
  margin-right: 0.5rem;
  transform: translateY(1px);
  cursor: pointer;
}

.pass-link  {
  margin: 0px;
  color: #43cdc8;
  cursor: pointer;
  font-family: "Roboto", sans-serif !important;
}

.login-cta {
  text-align: center;
  font-size: 0.8rem;
  color: #43cdc8;
  cursor: pointer;
  margin: 0;
  font-family: "Roboto", sans-serif !important;
}

.otp_link {
  font-size: 12px;
  color: rgb(73, 88, 170);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin: 0px;
  font-family: "Roboto", sans-serif !important;
}

.resend-otp {
  margin: 0px;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(73, 88, 170);
  font-family: "Roboto", sans-serif !important;
}

.otp-inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.otp-inputs-container input {
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0;
  text-align: center;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif !important;
}

.resend-otp-disabled {
  color: gray;
  pointer-events: none;
  cursor: not-allowed !important;
  margin: 0px;
  font-family: "Roboto", sans-serif !important;
}

.otp-timer-container {
  margin-bottom: 1rem;
}

.otp-timer-container p {
  font-size: 0.8rem;
  margin: 0.5rem 0 1rem 0;
  text-decoration: underline;
  text-align: right;
  color: #a2a2a2;
  font-family: "Roboto", sans-serif !important;
}

.otp_timer {
  font-size: 12px;
  color: rgb(73, 88, 170);
  font-weight: bold;
  margin: 0px;
  width: fit-content;
  font-family: "Roboto", sans-serif !important;
}

.login-btn {
  width: 100% !important;
  margin: 1rem 0;
  padding: 0.65rem;
  font-size: 0.8rem;
  background-color: #43cdc8;
  color: white;
  font-weight: 500;
  border-radius: 12px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.25s ease-in;
  font-family: "Roboto", sans-serif !important;
  display: grid;
  place-items: center;
}

.login-btn:hover {
  background-color: white;
  color: #43cdc8;
  border-color: #43cdc8;
}

.login-btn .spinner {
  margin: 0 !important;
}

.input-sign {
  text-align: center;
  font-size: 0.9rem;
  color: #a2a2a2;
  cursor: pointer;
  margin-top: 5px;
  font-family: "Roboto", sans-serif !important;
}

.input-sign span {
  margin-left: 0.5rem;
  color: #43cdc8;
  text-decoration: underline;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

@media only screen and (max-width: 750px) {
  .saveo1_logo {
    display: none;
  }

  .login-cntr {
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
     background-image: linear-gradient(
      335deg,
      hsl(178deg 60% 55%) 0%,
      hsl(206deg 37% 52%) 12%,
      hsl(245deg 37% 43%) 62%
    );
    background-color: transparent;
    background-attachment: fixed;
  }

  .saveo_logo {
    display: block;
    text-align: center;

    margin-top:2%;

    height: auto;
    width:40%;
  }

  .login-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 1rem 0;
  }

  .login-panel {
    height: 500px;
    width: 90%;

    text-align: center;
    margin: 0 px;
    margin-top: 10%;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  .login-header {
    text-align: center;
  }
  .login-inpt-box {
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }

  .input-sign {
    /* margin-top: 2rem;*/
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width:768px) {
  .saveo1_logo {
    display: none;
  }

  .saveo_logo {
   
    width: 200px; 
    height: auto;
  }
  .saveo_logo {
    display: block;
    text-align: center;

    margin-bottom:10%;

    height: auto;
    width:30%;
  }

  .login-cntr {
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    background-image: linear-gradient(
      335deg,
      hsl(178deg 60% 55%) 0%,
      hsl(206deg 37% 52%) 12%,
      hsl(245deg 37% 43%) 62%
    );
    background-color: transparent;
    background-attachment: fixed;
  }

  .login-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-panel {
    width: 85%;
    padding: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
}

