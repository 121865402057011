.dots_container div {
    /* display: inline-block; */
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
    background-color: #D1D1D1;
    margin: 0px 3px;
  }
  .dots_container{
    height: 15px !important;
  }
  .dots_container div:nth-last-child(1) {
    animation: jumpingAnimation 0.6s 0.1s ease-in infinite;
  }
  .dots_container div:nth-last-child(2) {
    animation: jumpingAnimation 0.6s 0.2s ease-in infinite;
  }
  .dots_container div:nth-last-child(3) {
    animation: jumpingAnimation 0.6s 0.3s ease-in infinite;
  }
  
  @keyframes jumpingAnimation {
    0% {
      transform: translate3d(0, 0,0);
    }
    50% {
      transform: translate3d(0, 7px,0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }