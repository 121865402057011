.issues_filters{
    padding: 0px 16%;
    flex-wrap: wrap;
  }
  .issues_tickets_section{
    padding: 0px 16%;
    /* justify-content: space-between; */
    margin-top: 3%;
    height: 63vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    /* display: flex; */
}
.issues_list_div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
  .issue_ticket_card{
      width: 440px;
      border: 1px solid rgba(128, 128, 128, 0.274);
      height: fit-content;
      padding: 10px;
      border-radius: 8px;
     margin-bottom: 20px;
     background-color: white;
  }
  .issue_ticket_card:hover{
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.086), 0px 0px 50px rgba(0, 0, 0, 0.086);
  }
  .issue_ticket_desc{
    width: 350px;
    text-overflow: ellipsis ;
    overflow: hidden ;
    white-space: nowrap; 
    font-size: 14px;
    color: #474747;
  }
  .issue_ticket_store_name{
    width: 250px;
    text-overflow: ellipsis ;
    overflow: hidden ;
    white-space: nowrap;
    font-weight: bolder; 
  }
  .issue_ticket_footer {
   height: 25px;
   border-top: 1px solid rgba(128, 128, 128, 0.293);
   margin-top: 10px;
   padding-top: 5px;
   color: #474747;

  }
  .emptyIssues_screen{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .emptyIssues_screen_img{
      width: 100%;
      height: 60%;
      margin-top: 5%;
  }
  .emptyIssues_screen_desc {
    font-size: 14px;
    color: #474747;
    line-height: 10px;
    text-align: center;
    margin-top: 10%;
  }
 
.retSearch{
    font-size: 12px !important;
    height: 35px;
    margin: 0px 10px;
    width: 245px;
}
.raise_issue_btn_mb button{
    font-size: 12px ;
}
.raise_issue_btn_mb{
    margin-right: 5%;

}
.deleteData_cont{
    height: 80vh;
    padding-top: 9rem;
    text-align: center;
}
  @media only screen and (max-width: 1000px) and (min-width: 751px) {
.issues_filters{
    padding: 0px 10%;
}
 .issues_tickets_section{
    height: 75vh;
    justify-content: center;
 }
 .issue_ticket_card{
    width: 500px;
 }
 .retSearch{
    margin-top: 10px;
 }
  }

  @media only screen  and (min-width: 390px) and (max-width: 500px){
   
.issues_filters{
    padding: 0px;
    font-size: 10px;
    
}
 .issues_tickets_section{
    height: 60vh;
    justify-content: center;
    padding: 0px;
 }
 .issues_list_div{
    justify-content: center;
 }
 .issue_ticket_card{
    width: 390px;
    padding:10px 5px;
}
.issue_ticket_desc{
    width: 300px;
}
.issue_ticket_store_name{
    width: 200px;
}
.issue_ticket_header{
    line-height: 15px;
}
.issues_tickets_section::-webkit-scrollbar{
    display: none;
}


.raise_issue_btn_mb button{
    font-size: 10px !important;
    width: 100px;
    white-space: nowrap;
    height: 30px !important;
}

.retSearch{
    margin-top: 10px;
 }
  } 


  @media only screen and (max-width: 389px){
 
    .issues_filters{
        padding: 0px;
        font-size: 10px;
    }
     .issues_tickets_section{
        height: 60vh;
        justify-content: center;
        padding: 0px;
     }
     .issues_list_div{
        justify-content: center;
     }
     .issue_ticket_card{
        width: 350px;
        padding:10px 5px;
    }
    .issue_ticket_desc{
        width: 250px;
    }
    .issue_ticket_store_name{
        width: 200px;
    }
    .issue_ticket_header{
        line-height: 15px;
    }
    .issues_tickets_section::-webkit-scrollbar{
        display: none;
    }
   
    .raise_issue_btn_mb button{
        font-size: 10px !important;
        width: 100px;
        white-space: nowrap;
        height: 30px !important;
    }
    .retSearch{
        margin-top: 10px;
     }
    
      } 