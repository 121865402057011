.productList_cont {
  background-color: #f2f7f7;
  height: calc(100vh - 7rem);
  overflow: hidden;
  margin-top: 7rem;
  max-width: 1900px;
}


.productListDiv{
  background-color: #F2F7F7;
  width: 100%;
  margin: 0px auto;
  height: calc(100vh - 12rem);
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  padding-top: 8rem;
}
.productList {
  background-color: #F2F7F7;
  width: 100%;
  margin: 0px auto;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll !important;
  scroll-behavior: smooth;
  padding-top: 8rem;

}
.productListHeader {
  background-color: #F2F7F7;
  font-size: 20px;
  padding: 30px 15px 8px 30px;
  text-align: center;
  font-weight: bolder;
  color: #4b4495;
  position: sticky;
  top: 0%;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  z-index: 2;
  display: flex;
  align-items: center;
}
.productListHeader span {
  display: block;
  margin-top: 0.25rem;
  margin-left: 1rem;
  max-width: 500px;
  color: #68737a;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.infiniteScroll {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.goBack {
  font-size: 20px;
  color: #68737a;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.goBack:hover {
  color: #22b6fa;
}
.pageListHeaderWithSearch{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.page_search_div  .productListsearchBarDiv{
  background-color: white !important;
    border:1px solid rgba(128, 128, 128, 0.236) !important;
    border-radius: 20px;
    width: 30vw;
    margin: 0px auto;
}
.page_search_div .searchBar{
  background-color: white !important;
  border: none !important;
  border-radius: 20px;

}
.page_search_div .search_icon{
    background-color: rgba(128, 128, 128, 0);
}
.productListsearchBarDiv .searchBar:focus{
  box-shadow:  24px 24px 37px #d9d9d9,
               -24px -24px 37px #ffffff !important;
}

@media only screen and (max-width: 750px) {
    .productListHeader{
      font-size: 14px;
      padding: 10px;
      width: 100%;
    }
    
    .productList{
        padding-top: 7.5rem;
        height: 93vh;
    }
    .productListDiv{
      padding-top: 7.5rem;
        height: 73vh;
    }
    .productListDiv::-webkit-scrollbar{
      display: none;
    }
}
@media only screen and (max-width: 1001px) and (min-width:751px){
    .productListHeader{
        margin-top: 20px;
        top: 1%;
        width: 100%;

    }
    .productList{
        padding-top: 7.5rem;
        height: 95vh;
    }
    .productListDiv{
      padding-top: 7.5rem;
        height: 75vh;
    }
    .productListDiv::-webkit-scrollbar{
      display: none;
    }

    .productListHeader span {
      max-width: 300px;
    }
}

@media only screen and (max-width: 600px) {
  .productListHeader {
    display: block;
  }

  .productListHeader span {
    overflow: unset;
    white-space:normal;
    margin-left: 0;
    background-color: #c1efee;
    border-radius: 3px;
    padding: 0.2rem 0.5rem;
    color: #376e6c;
  }
}
