.voice_assistant{
    position: fixed;
    top: 87dvh;
    left: 94dvw;
    z-index: 12 !important;

}
.voice_assistant_outer_div{
    position: fixed;
    height: 100dvh;
    width: 100dvw;
    top: 0px;
    left: 0px;
    z-index: 12 !important;
}

.voice_assistant_logo{
    border-radius: 50%;
    transition: transform 300ms ease-out;
}
.voice_assistant_logo:hover{
    transform: translate(0px, 0px) scale(1.2, 1.2);
}
.voice_assistant_logo svg{
    border-radius: 50%;
    cursor: pointer;
}


.voice_assistant_popup{
    height: 660px;
    width: 400px;
    position: fixed;
    right: 10px;
    bottom:3%;
    background-color: #F3F3F3;
    border-radius: 15px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.106);
    overflow-y: scroll;
    overflow-x: hidden;
}
.voice_assistant_popup::-webkit-scrollbar{
    display: none;
}
.capsi_wave{
    width: 100px;
    margin: 0px auto;
    padding-bottom: 5px;
}

.capsi_wave_header{
width: 100%;
background: #48D1CC;
background: radial-gradient(at center top, #48d1cdf2, #4B4495);
padding: 20px 10px;
border-top-right-radius: 15px;
border-top-left-radius: 15px;
}
.capsi_wave_header_curve{
    height: 30px;
    width: 115%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #F3F3F3;
    position: absolute;
    margin-top: -5%;
    margin-left: -8%;
    z-index: 0 !important;

}

.capsi_wave_laguage_select_body{
    background-color: #F3F3F3;
    width: 100%;
    padding: 5px 15%;
}
.lang_radio_div{
    width: 280px;
    background-color: white;
    border: 1px solid #d1d1d18b;
    border-width: thin;
    margin: 10px 0px !important;
    border-radius: 10px;
    padding:5px 10px;
}
.lang_select_div{
    max-height: 320px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}
.lang_select_div::-webkit-scrollbar{
    display: none;
    width: 2px;
   color: rgba(128, 128, 128, 0.195);
}
.lang_radio_btn{
    color: #48D1CC !important;
}
.lang_select_btn{
    width: 200px;
    border-radius: 10px !important;
    padding: 10px !important;
    margin-top: 10px !important;
}
.capsi_desc_header{
    line-height: 6px;
    padding-top: 10px;
    font-size: 14px;
}
.capsi_msg_dec{
    width: 280px;
    background-color: white;
    border: 1px solid #d1d1d18b;
    border-width: thin;
    margin: 10px 0px !important;
    border-radius: 10px;
    padding:5px 10px;
    font-size: 12px;
    font-weight: 500;
}
.capsi_msg_dec_arrow_rightend{
    position: absolute;
    margin-top: 42px;
    font-size: 20px;
    margin-left: 25%;
    color: #b3b3b3d6;
}
.capsi_msg_dec_arrow_leftend{
    position: absolute;
    margin-top: 42px;
    font-size: 20px;
    margin-left: -30%;
    color: #b3b3b3d6;
}
.va_setup_modal_outer_div{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    left: 0px;
    background-color: rgba(65, 65, 65, 0.337);
    z-index: 10 !important;
    display: flex;
    align-items: center;
}
.va_welcome_page{
    padding: 10px;
    background-color: #F3F3F3;
    height: 100%;
}
.capsi_wave_lg{
    width: 150px;
    margin: 0px auto;
    padding-bottom: 5px;
}
.va_welocome_page_header{
    margin-top: 10%;
    text-align: center;
}
.va_welocome_page_body{
    margin-top: 10%;
}
.va_welocome_page_qsn{
    width: 280px;
    border: 1px solid #656565;
    border-width: thin;
    margin: 20px auto !important;
    border-radius: 15px;
    padding:15px 10px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}
.va_welocome_page_footer{
    padding: 10px;
    position: absolute ;
    bottom:10px ;
    width: 95%;
}
.va_welocome_page_footer > div{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    height: 60px;
}
.va_menu_btn{
    background-color: white;
    padding: 8px;
    border-radius: 12px;
    font-size: 25px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: #48D1CC !important;
    cursor: pointer;
}
.va_microphone_btn{
    background: rgb(72,209,204);
    background: linear-gradient(0deg, rgba(72,209,204,1) 0%, rgba(75,68,149,1) 58%);
    padding: 8px;
    border-radius: 20px;
    font-size: 23px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: white !important;
    cursor: pointer;
    transition: transform 300ms ease-out;
}
.va_microphone_btn:hover{
    transform: translate(0px, 0px) scale(1.2, 1.2);
}

.va_keyboard_btn{
    background-color: white;
    padding: 8px;
    border-radius: 12px;
    font-size: 25px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: #48D1CC !important;
    cursor: pointer;
}

.va_keyboard_footer{
    background-color: white;
     width: 100%;
     border-radius: 10px;
}
.va_input_box {
    width: 250px;
    background-color: #F3F3F3;
    border-radius: 15px;
    font-size: 14px;
    border: none;
}
.va_input_box input{
    border: none;
    width: 220px;
    background-color: #F3F3F3;
    border-radius: 15px;
    font-size: 14px;
    border: none;
    padding: 10px;
}
.va_key_search{
    cursor: pointer;
    transition: transform 300ms ease-out;
}
.va_key_search:hover{
    transform: translate(0px, 0px) scale(1.4, 1.4);
}

.wave_loader svg{
    width: 30px !important;
}
.qa_list_div{
    margin-top: 10px;
    height: 530px;
    overflow-y: scroll;
}
.qa_list_div::-webkit-scrollbar{
    display: none;
}
.convo_div{
  padding:0px 10px;
}
.convo_div div:first-child{
    width: 100%;
    display: flex;
    justify-content: end;
    height: fit-content;

}
.convo_div div:last-child{
    width: 100%;
    display: flex;
    justify-content: start;
    height: fit-content;

}
.convo_div div:first-child p{
    width: fit-content;
    height: fit-content;
    padding: 10px;
    font-size: 12px;
    background-color: #E3F8F7;
    border: 1px solid #86E1DD;
    border-radius: 10px;
    margin: 10px 0px 10px 10px;

}
.answer_div{
    width: fit-content !important;
    height: fit-content;
    padding: 10px;
    font-size: 12px;
    background-color: white;
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    margin-right: 10px;
}

.audio_controls_div{
    display: flex;
    background-color: #E5E5E5;
    height: 35px;
    align-items: center !important;
    width: fit-content;
    border-radius: 6px;
}
.audio_controls{
    font-size: 20px;
    border-radius: 6px;
    background-color: #E5E5E5;
    padding: 0px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.vs_close_btn{
    width: 100%;
    text-align: end;
    cursor: pointer;
}
.vs_close_btn svg{
    color: #B9B9B9 !important;
}


@media only screen and (max-width: 1001px) and (min-width:651px){
    .voice_assistant{
        left: 91dvw;
    }
    .voice_assistant_popup{
        bottom: 6%;
        right:5px;
    }
}

@media only screen and (max-width: 650px){
    .voice_assistant{
        left: 82dvw;
        top: 85dvh;

    }
    .voice_assistant_popup{
        bottom: 0%;
        right:0px;
        width:100%
    }
}

