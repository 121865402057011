/* Product load shimmer */
.productShimmer {
  display: grid;
  place-items: center;
  height: 100vh;
}

.productShimmer .productShimmer_subcntr {
  width: 60%;
  height: 60vh;
  padding: 1rem;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 20px 20px rgb(0 0 0 / 9%), 0px 0px 50px rgb(0 0 0 / 13%);
}

.productShimmer_top {
  display: flex;
  justify-content: space-between;
}

.productShimmer_img {
  height: 200px;
  width: 200px;
  margin-bottom: 1rem;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.productShimmer_content {
  width: 200px;
  height: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  margin-bottom: 0.5rem;
}

.productShimmer_subdiv {
  height: 100%;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.border_rad_7 {
  border-radius: 7px !important;
}

.bg_white {
  background-color: white !important;
}

.p_8 {
  padding: 0.5rem;
}

.p_16 {
  padding: 1rem;
}

.mt_16 {
  margin-top: 1rem;
}

.product_section_cont {
  margin-top: 7rem;
  padding: 2%;
  min-height: 70vh;
  width: 100%;
  background-color: #F2F7F7;
}

section {
  display: flex;
  justify-content: space-between;
  position: relative;
}

section > div {
  padding: 0.5rem;
}

.l-section, .r-section {
  width: 200px;
}

.m-section {
  flex: 1;
  max-width: calc(100% - 500px);
}

.back-nav {
  cursor: pointer;
  margin: 0;
  margin-bottom: 1rem;
  font-size: 12px;
  font-weight: bold;
  color: #4b4495;
  letter-spacing: 1px;
  transition: all 0.25s ease-in;
}

.back-nav:hover svg {
  color: #48d1cc;
}

.back-nav span:first-child {
  font-size: 16px;
}

.back-nav span:last-child {
  color: #9399a4;
}

/* Product Data component on top*/
/* First part of product data component */
.prod_data_top {
  background-color: #4b4495;
  display: flex;
  justify-content: space-between;
}

.pd_t_l {
  display: flex;
}

.pd_t_l div:nth-child(2) {
  margin-top: 0.5rem;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.pd_img {
  background-color: white;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  place-items: center;
  padding: 0.5rem;
  transition: all 0.25s ease-in-out;
  position: relative;
}

.pd_img_cursor {
  margin: 0 !important;
  display: block !important;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px !important;
  border: 1px solid #0ca9a2;
  background-color: #17706b7a;
}

.pd_img_zoom > div {
  position: absolute;
  padding: 2rem;
  zoom: 200%;
  width: 200px;
  height: 200px;
  z-index: 9;
  left: 40%;
  background-color: white;
  background-origin: content-box;
  background-repeat: no-repeat;
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 0px 6px 6px #32286752;
  transition: all 0.25s ease-in-out;
  animation: zoomIn 0.3s forwards ease-out;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pd_img img {
  width: 100%;
}

.pd_sale {
  background-image: linear-gradient(to right, #f3caea, #f9ecf7);
  max-height: fit-content;
  width: fit-content;
  border: 1px solid #f3caea;
  border-radius: 0px 4px 4px 0px;
  padding: 0.5rem;
  box-shadow: 0px 6px 6px #32286752;
}

.pd_sale p {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.pd_sale p span {
  margin-left: 0.5rem;
  font-weight: 400;
  color: #4a5155;
}

.pd_sale svg {
  color: #9c1e80;
  margin-right: 0.5rem;
}

.pd_sale p:last-child {
  color: white;
  font-weight: 400;
  padding: 0 0.5rem;
  background: transparent linear-gradient(90deg, #a93b9b 0%, #6f0fb6 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 2px #00000029;
  border: 0.5px solid #c719b1;
  border-radius: 2px;
}

.pd_sale p:last-child::after {
  content: "⌃";
  transform: rotate(90deg);
  margin-left: 1rem;
}

.p_data {
  padding: 0 1rem;
}

.p_data h4 {
  font-size: 16px;
  color: white;
}

.p_data p {
  font-size: 12px;
  font-weight: 600;
  color: #02c3b0;
}

.p_data span {
  color: #9399a4;
  margin-right: 0.5rem;
}

.pd_t_r div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pd_t_r p {
  pointer-events: none;
  text-align: right;
  min-width: 120px;
  padding: 0 0.5rem;
  text-align: center;
  font-size: 15px;
  color: #dcd9fc;
  letter-spacing: 1px;
  background-color: #5e58a7;
  border-radius: 3px;
}

.prod_SubSectionType {
  font-weight: 500 !important;
  background-color: #caf1ef !important;
  color: #17706c !important;
}

/* Second part of product data component */
.prod_data_bottom {
  display: flex;
  justify-content: space-around;
}

.pd_pricing {
  display: flex;
  gap: 1rem;
}

.pd_pricing p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  margin: 0;
}

.pd_pricing_cd {
  background-color: #c719b1;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 3px;
  font-weight: 600;
}

.pd_pricing_cd span {
  font-weight: 300;
}

.pd_pricing_ptr {
  color: #9399a4;
  font-size: 14px;
}

.pd_pricing_ptr span {
  color: black;
  font-weight: 600;
  font-size: 16px;
}

.pd_pricing > div {
  color: #4b4495;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  margin-top: auto;
}

.pd_pricing > div:hover .price_breakup {
  display: block;
}

.price_breakup {
  display: none;
  position: absolute;
  top: 1.5rem;
  left: -8rem;
  width: 250px;
  border: 1px solid #000000;
  border-radius: 3px;
  box-shadow: 0px 6px 6px #32286752;
  z-index: 1;
  animation: openInfo 0.25s forwards ease-in;
}

.price_breakup::before {
  content: " ";
  position: absolute;
  right: 50px;
  top: -10px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid black;
}

.price_breakup p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.35rem 0.5rem;
  color: #494949;
  text-decoration: none !important;
}

.price_breakup p:first-child {
  border-bottom: 1px solid #e0e0e0;
}

.price_breakup_margin {
  background-color: #ddfffd;
  color: #17706c !important;
  font-size: 11px;
  font-weight: 400;
}

.price_breakup_margin span {
  font-weight: 600;
}

.price_breakup span p:first-child {
  border-top: 1px solid #e0e0e0;
  border-bottom: none;
}

.price_breakup > span {
  background-color: #f7f7f7;
  border-radius: 0 0 3px 3px;
}

.price_breakup span p,
.price_breakup p:last-child {
  font-size: 11px;
  font-weight: 400;
}

.price_breakup .pb_cd {
  background-color: #ffe1fb;
  color: #a93b9b !important;
}

.price_breakup .pb_gst {
  color: #494949 !important;
}

.pd_b_l_metadata p {
  font-size: 11px;
  display: flex;
  margin: 0;
  min-width: 80px;
}

.pd_b_l_metadata > div span:not(:first-child) {
  color: #68737a;
  font-size: 13px;
  font-weight: 600;
}

.pd_b_l_metadata > div .meta_colon {
  margin-right: 0.2rem;
}

.pd_b_l_metadata > div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.pd_margin {
  color: #886716;
  font-weight: 400 !important;
  background-color: #ffe600;
  padding: 0.1rem 0.5rem;
  margin: 0 0.5rem;
  border-radius: 2px;
}

.pd_inStock {
  background-color: #ddfffd;
  color: #17706c !important;
  padding: 0 0.5rem;
  border: 1px solid #17706c;
  border-radius: 3px;
}

.pd_schemes_span {
  border: 1px solid #68737a !important;
  border-radius: 3px !important;
  padding: 0 0.3rem !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.pd_schemes_span:not(:last-child) {
  margin-right: 0.3rem;
}

/* Add to Cart section */
.pd_b_r {
  display: grid;
  place-items: center;
  position: relative;
}

.pd_b_r > div {
  border: 1px solid #4b4495;
  border-radius: 3px;
  min-width: 250px;
}

.pd_qty {
  padding: 0.5rem 1rem 0 1rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.pd_qty input {
  margin-left: 1rem;
  border: 1px solid #48d1cc;
  border-radius: 3px;
  width: 70px;
  text-align: center;
  padding: 0;
  background-color: transparent;
}

.add_qty_btn {
  width: 100%;
  background-color: #48d1cc;
  border: transparent;
  color: white;
}

.add_more_cart_info {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 12px;
  min-width: 150px;
}

.pd_maxMsg {
  animation: maxMessage .25s forwards ease-in;
}

.pd_offer {
  cursor: pointer;
  font-size: 12px;
  margin: 0 1rem 0.5rem 1rem;
  padding: 0.2rem 1rem;
  border-radius: 3px;
  text-align: center;
  animation: offerMsg .25s forwards ease-in;
}

@keyframes offerMsg {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pd_b_r .add_cart {
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #4b4495;
  border: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pd_b_r .add_cart p {
  margin: 0;
  text-align: left;
  font-size: 12px;
}

.pd_b_r .add_cart p span {
  font-weight: 400;
  font-size: 10px;
}

.pd_b_r .add_cart .ac_r_arrow {
  padding: 0 1rem;
  font-size: 20px;
  border-left: 1px solid #ffffff80;
}

.add_more_cart {
  background-color: transparent !important;
}

.add_more_cart div {
  height: 40px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.add_more_cart div:hover {
  background-color: #02c3b0 !important;
}

.product_pg_minus_btn {
  background-color: #dcd9fc;
  width: 100%;
  border-bottom-left-radius: 2px;
}

.product_pg_add_btn {
  background-color: #4b4495;
  width: 100%;
  border-bottom-right-radius: 2px;
}

.hyperlink {
  min-width: 150px;
  text-decoration: underline;
  color: #0ca9a2;
  font-size: 14px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hyperlink span {
  background-color: #0ca9a2;
  color: white;
  border-radius: 50%;
  padding: 0.3rem;
  margin-right: 0.5rem;
  display: grid;
  place-content: center;
  width: fit-content;
}

.pd_b_r .hyperlink {
  position: absolute;
  bottom: 0;
  right: 1rem;
}

.pd_b_r .hyperlink span {
  width: 25px;
  height: 25px;
}

/* Product substitute component */

.prod_substitutes {
  margin: 1rem 0;
  background-color: #e8e8e8;
}

.prod_header {
  display: flex;
  justify-content: space-between;
}

.prod_header h6 {
  color: #323232;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.prod_header h6 span {
  font-size: 12px;
}

.prod_header p {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
}

.prod_header p span {
  margin-left: 0.5rem;
  color: #49494952;
  transition: all 0.25s ease-in-out;
}

.prod_header p:hover span {
  letter-spacing: 10px;
  color: #171e30;
}

.ps_info {
  font-size: 12px;
}

.salt_comp {
  margin-top: 1rem;
  background-color: #e7fffe;
  border: 1px solid #17706c;
  border-radius: 5px;
  padding: 0.5rem;
}

.salt_comp h4 {
  font-size: 14px;
  font-weight: bold;
  color: #17706c;
}

.salt_comp p {
  margin: 0;
  font-size: 12px;
  color: #171e30;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.5s ease-in;
}

.salt_comp p:hover {
  color: #514aa1;
  font-weight: bold;
}

/* Product Substitute Cards */
.substitute_cntr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start !important;
  gap: 1rem;
  overflow-y: auto;
}

.substitute_cntr::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.substitute_cntr::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.substitute_cntr::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.substitute_cntr::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.ps_card_cont {
  display: flex;
  gap: 1rem;
  width: fit-content;
  cursor: pointer;
  /* overflow-y: auto; */
}

.ps_card {
  padding: 0.5rem;
  border-radius: 5px;
}

.ps_card_details {
  display: flex;
}

.ps_card_error p {
  margin: 0;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 12px;
  color: crimson;
}

.ps_card_left h6 {
  font-size: 14px;
  font-weight: bold;
  color: #323232;
  width: 200px;
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
  cursor: pointer;
}

.ps_card_left > p {
  font-size: 10px !important;
  width: 100%;
  color: #9399a4;
  margin: 0;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #caf1ef;
  display: flex;
  justify-content: space-between;
}

.ps_card_left > p span {
  width: 170px;
  color: #0ca9a2;
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
  cursor: pointer;
}

.ps_card_left div {
  display: flex;
  justify-content: space-between;
}

.ps_card_left_1 {
  margin: 0.5rem 0;
  min-height: 2.1rem;
}

.ps_card_left_1 p {
  font-size: 12px;
  padding: 0.5rem;
  font-weight: bold;
  min-width: 80px;
  text-align: center;
  margin: 0;
}

.ps_card_left_1 p:nth-child(1) {
  clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  background-color: #c719b1;
  padding-right: 1rem;
  color: white;
}

.ps_card_left_1 p:nth-child(2) {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
  background-color: #caf1ef;
  padding-left: 1rem;
  color: #17706c;
}
.cart_gen_card_left_1 p:nth-child(1) {
  background-color: #FFDDDC !important;
  color: #000000 !important;
}
.cart_gen_card_left_1 p:nth-child(2) {
  background-color: #FAFAFA !important;
  color: #F46D6A !important;
}
.ps_card_left_2 {
  border: 1px solid #caf1ef;
  border-radius: 3px;
  padding: 0.2rem 0.5rem;
}

.ps_card_left_2 p {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  width: 50%;
}

.ps_card_left_2 p span {
  font-size: 10px;
  color: #9399a4;
}

.ps_card_left_2 p:first-child {
  border-right: 1px solid #e6e6e6;
  color: #9399a4;
}

.ps_card_left_2 p:last-child {
  text-align: right;
}

.ps_card_right {
  width: 150px;
  position: relative;
}
.cart_gen_pc_right{
  width: 125px !important;
}
.cart_gen_pc_right img{
  width: 75px !important;
}
.cart_gen_pc_right button{
  background-color: #F46D6A !important;
}

.ps_card_right img {
  position: absolute;
  top: -0.8rem;
  left: 2rem;
  width: 90px;
}

.ps_card_right p {
  margin: 0;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-35%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #886716;
}

.ps_card_right p span {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.ps_card_right .add_cont {
  position: absolute;
  bottom: 0;
  width: 85%;
  padding: 0.5rem 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ps_card_right button {
  position: absolute;
  bottom: 0;
  width: 85%;
  padding: 0.5rem 0.2rem;
  font-weight: bold;
  color: white;
  background-color: #48d1cc;
  border: none;
  border-radius: 3px;
}

/* Product Details Section */
.prod_details h6 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.prod_details h6:not(:first-child) {
  margin-top: 1.5rem;
}

.prod_details p {
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.prod_details p span {
  color: #68737a;
}

.prod_details p span:first-child {
  display: inline-block;
  width: 100px;
}

.p_dtl_salt {
  color: #16a6f2;
}

/* Medicine group pill */
.med_group p {
  width: fit-content;
  display: inline-block;
  padding: 0.5rem;
  border: 1px solid #68737a;
  border-radius: 25px;
  font-size: 11px;
}

.med_group p:not(:first-child) {
  margin-left: 1rem;
}

/* Medicine Group Modal */
.med_group_cont {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1rem 0;
}

.med_group_cont h6 {
  margin: 0;
}

.med_group_query {
  margin-left: 0.5rem;
  position: relative;
  cursor: pointer;
}

.med_group_query:hover .med_group_modal {
  display: block;
}

.med_group_modal::before {
  content: " ";
  position: absolute;
  left: -10px;
  top: 3px;
  border-left: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid black;
}

.med_group_modal {
  display: none;
  position: absolute;
  top: 0px;
  left: 25px;
  width: 250px;
  border: 1px solid #171e30;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 6px 6px #32286752;
  animation: openInfo 0.25s forwards ease-in;
}

.med_group_modal p {
  color: #323232;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: center;
}

@keyframes openInfo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Right Cart Section */
.m-r-section {
  width: 300px;
}

/* Right Cart Section */
.r-section {
  position: fixed;
  right: 0.5rem;
  width: 300px;
  /* box-shadow: 0px 3px 10px 1px #1f1f1f25; */
}

@media only screen and (max-width: 1200px) {
  .l-section {
    width: 100px;
  }

  .m-section {
    max-width: calc(100% - 400px);
  }

  .pd_img_zoom > div {
    left: 35%;
  }
}

@media only screen and (max-width: 940px) {
  .l-section {
    width: 50px;
  }

  .m-section {
    max-width: calc(100% - 350px);
  }

  .pd_img_zoom > div {
    left: 35%;
  }
}

@media only screen and (max-width: 900px) {
  section {
    margin-top: 2rem;
  }

  .l-section {
    display: none;
  }

  .m-section {
    max-width: calc(100% - 300px);
  }

  .pd_img_zoom > div {
    left: 30%;
    width: 400px;
    height: 400px;
  }
}

@media only screen and (max-width: 800px) {
  .product_section_cont {
    margin-top: 5rem;
  }

  .m-section {
    max-width: 100%;
  }
  
  .m-r-section,
  .r-section {
    display: none;
  }

  .prod_data_top {
    flex-direction: column;
  }

  .pd_t_r {
    margin-top: 1rem;
  }
  
  .pd_t_r div {
    align-items: flex-start;
  }

  .pd_img_zoom > div {
    left: 35%;
    top: 4rem;
  }
}

@media only screen and (max-width: 700px) {
  .pd_sale p {
    font-size: 50%;
  }

  .pd_img_zoom > div {
    left: 40%;
    width: 350px;
    height: 350px;
  }
}

@media only screen and (max-width: 600px) {
  .pd_img {
    width: 150px;
    height: 150px;
  }

  .pd_img_zoom {
    display: none;
  }

  .pd_t_l div:nth-child(2) {
    height: 150px;
  }

  .prod_data_bottom {
    flex-direction: column;
  }

  .pd_b_r {
    padding: 1rem 0;
    padding-bottom: 0;
  }

  .pd_b_r > div {
    width: 100%;
  }

  .pd_b_r .hyperlink {
    position: unset;
    padding-top: 0.5rem;
    text-align: left;
    width: 100%;
  }

  .pd_t_r p {
    margin: 0.2rem 0;
    font-size: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .pd_img {
    width: 100px;
    height: 100px;
  }

  .p_data {
    padding: 0 0.5rem;
  }

  .p_data h4 {
    display: none;
  }

  .p_data p {
    margin: 0;
    font-size: 60%;
    display: flex;
    flex-direction: column;
  }

  .pd_t_l div:nth-child(2) {
    height: fit-content;
  }
}
